import { INavigationLink } from './interfaces';

const navigationLinks: INavigationLink[] = [
  {
    display: 'HOME',
    link: '/',
    key: '/',
  },
  {
    display: 'BLOG',
    link: '/blog',
    key: '/blog',
  },
  {
    display: 'ABOUT',
    link: '/about',
    key: '/about',
  },
  {
    display: 'PROJECTS',
    link: '/projects/renovation',
    key: '/projects/[slug]',
  },
  {
    display: 'SERVICES',
    link: '/services/interior-design',
    key: '/services/[slug]',
    nested: [
      {
        display: 'DESIGN',
        link: '/services/interior-design',
        key: '/services/interior-design',
      },
      {
        display: 'CONTENT',
        link: '/services/content-creation',
        key: '/services/content-creation',
      },
    ],
  },
  {
    display: 'CONTACT',
    link: '/contact',
    key: '/contact',
  },
];

export default navigationLinks;
