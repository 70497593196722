import React from 'react';
import Metatags from '../Metatags';
import ShowIf from '../ShowIf';
import NavigationContent from './NavigationContent';
import MobileNavigation from './MobileNavigation';
import { INavigationProps } from './interfaces';

const Navigation: React.FC<INavigationProps> = ({ showLogo = true }) => {
  return (
    <>
      <Metatags />
      <ShowIf device="desktop">
        <NavigationContent showLogo={showLogo} />
      </ShowIf>
      <ShowIf device="mobile">
        <MobileNavigation />
      </ShowIf>
    </>
  );
};

export default Navigation;
