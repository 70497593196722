import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const NavigationContainer = styled(Box)``;

export const NestedWrapper = styled(Box)`
  transition: all 0.3s ease-in-out;
  :hover ${NavigationContainer} {
    opacity: 1;
  }
`;
