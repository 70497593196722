import styled from 'styled-components';
import { BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { INavLinkProps } from '../interfaces';
import {
  NestedWrapper,
  NavigationContainer,
} from '../NestedNavigation/NestedNavigationStyled';
import { colorsGreen, colorsGrey } from '@src/theme/chakraTheme/colors';

export const StyledBreadcrumbItem = styled(BreadcrumbItem)`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  color: ${colorsGrey[500]};
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  padding-bottom: 0.5rem;
  cursor: pointer;
  &:first-child:after {
    width: 60%;
    left: 6%;
  }
  &:last-child:after {
    width: 70%;
    left: 15%;
  }
  ${({ theme }) => theme.BreakpointTablet`
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
    &.active:after {
      content: none;
    }
  `}

  :hover ${NestedWrapper},${NavigationContainer} {
    opacity: 1;
  }
`;

export const StyledBreadcrumbLink = styled(BreadcrumbLink)<INavLinkProps>`
  position: relative;
  &:after {
    transition: all 320ms ease-in-out;
    content: '';
    position: absolute;
    bottom: ${({ $isActive }) => ($isActive ? -4 : -13)}px;
    width: 70%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    background-color: ${colorsGreen[300]};
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  }

  ${({ $noUnderline }) =>
    !$noUnderline &&
    `
  &:hover:after {
    opacity: 1;
    bottom: -4px;`}
`;
