import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { INavigationLink } from '../../interfaces';
import LinkNavigation from './LinkNavigation';
import { colorsGreen, colorsGrey } from '@src/theme/chakraTheme/colors';

const AccordionNavigation: React.FC<INavigationLink> = ({
  display,
  link,
  nested,
}) => {
  const { asPath } = useRouter();
  return (
    <Accordion allowToggle key={link}>
      <AccordionItem>
        <Heading as="h2" mb="15px">
          <AccordionButton
            backgroundColor="white"
            outline="none"
            border="none"
            p={0}
            _hover={{ bgColor: 'white' }}
            _expanded={{ bgColor: 'white', p: 0 }}
          >
            <Text
              cursor="pointer"
              color={colorsGrey[500]}
              pos="relative"
              w="fit-content"
              _after={{
                opacity: asPath === link ? 1 : 0,
                content: '""',
                bgColor: colorsGreen[300],
                pos: 'absolute',
                w: '75%',
                h: '3px',
                left: 0,
                bottom: '-4px',
              }}
            >
              {display}
            </Text>
            <AccordionIcon color="grey.500" />
          </AccordionButton>
        </Heading>
        <AccordionPanel py={0} px={4}>
          {nested?.map(({ link: nestedLink, display: nestedDisplay }) => (
            <LinkNavigation
              key={nestedLink}
              link={nestedLink}
              display={nestedDisplay}
              textProps={{ mb: 4 }}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionNavigation;
