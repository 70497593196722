/* eslint-disable react/no-array-index-key */
import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { IBurgerButtonProps } from './interfaces';
import { colorsGrey } from '@src/theme/chakraTheme/colors';

const BurgerButton: React.FC<IBurgerButtonProps> = ({ onClick }) => {
  return (
    <Flex
      direction="column"
      onClick={onClick}
      w="46px"
      h="34px"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      justify="space-between"
      p="10px 14px"
      borderRadius="4px"
    >
      {[...Array(3)].map((_, i) => (
        <Box
          key={i}
          height="1px"
          backgroundColor={colorsGrey[500]}
          width="100%"
        />
      ))}
    </Flex>
  );
};

export default BurgerButton;
