import React from 'react';
import { Box } from '@chakra-ui/react';
import { IShowIfProps } from './interfaces';

const ShowIf: FCWithChildren<IShowIfProps> = ({ device, children }) => {
  if (device === 'desktop')
    return (
      <Box id="show-desktop" display={['none', null, 'block']}>
        {children}
      </Box>
    );
  return (
    <Box id="show-mobile" display={['block', null, 'none']}>
      {children}
    </Box>
  );
};

export default ShowIf;
