import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { INavigationLink } from '../interfaces';
import { StyledBreadcrumbLink } from '../NavigationContent/NavigationContentStyled';
import { NavigationContainer, NestedWrapper } from './NestedNavigationStyled';

const NestedNavigation: React.FC<{
  nested: Required<INavigationLink['nested']>;
}> = ({ nested }) => {
  const { pathname } = useRouter();

  if (!nested) return null;

  return (
    <NestedWrapper
      pos="absolute"
      top="24px"
      backgroundColor="transparent"
      paddingTop="28px"
      transition="opacity 0.3s ease-in-out"
      opacity={0}
    >
      <NavigationContainer
        display="flex"
        backgroundColor="white"
        px="9px"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        opacity={0}
      >
        {nested.map(({ link: nestedLink, display: nestedDisplay }, i) => (
          <React.Fragment key={nestedLink}>
            <Link href={nestedLink} passHref legacyBehavior>
              <StyledBreadcrumbLink
                _hover={{ color: 'primary' }}
                my="15px"
                $isActive={pathname === nestedLink}
                $noUnderline
              >
                {nestedDisplay}
              </StyledBreadcrumbLink>
            </Link>
            {i < nested.length - 1 && (
              <Box
                w="100%"
                borderBottomStyle="solid"
                borderBottomWidth="1px"
                borderBottomColor="secondary"
              />
            )}
          </React.Fragment>
        ))}
      </NavigationContainer>
    </NestedWrapper>
  );
};

export default NestedNavigation;
