import { Flex } from '@chakra-ui/react';
import React from 'react';
import { ICenteredFlexProps } from './interfaces';

const CenteredFlex: FCWithChildren<ICenteredFlexProps> = ({
  children,
  ...props
}) => (
  <Flex justify="center" align="center" {...props}>
    {children}
  </Flex>
);

export default CenteredFlex;
