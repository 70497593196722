import Link from 'next/link';
import React from 'react';
import { Flex } from '@chakra-ui/react';
import { INavigationBrandProps } from './interfaces';
import Logo from '@src/assets/Logo';

const NavigationBrand: React.FC<INavigationBrandProps> = () => {
  return (
    <Link href="/" passHref>
      <Flex align="center" cursor="pointer">
        <Logo />
      </Flex>
    </Link>
  );
};

export default NavigationBrand;
