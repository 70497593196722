import React from 'react';
import { useRouter } from 'next/router';
import { Fade, Breadcrumb, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import navigationLinks from '../navigationLinks';
import NavigationBrand from '../NavigationBrand';
import { NestedNavigation } from '../NestedNavigation';
import {
  StyledBreadcrumbItem,
  StyledBreadcrumbLink,
} from './NavigationContentStyled';
import { INavigationContentProps } from './interfaces';
import useOnScroll from '@src/hooks/useOnScroll';
import CenteredFlex from '@components/Shared/CenteredFlex';
import { colorsGrey } from '@src/theme/chakraTheme/colors';

const NavigationContent: React.FC<INavigationContentProps> = ({ showLogo }) => {
  const { pathname } = useRouter();
  const isSticky = useOnScroll(1);
  return (
    <CenteredFlex
      align="center"
      justify="center"
      margin="0 auto"
      w="100%"
      height="70px"
      pos={isSticky ? 'fixed' : 'absolute'}
      top={0}
      zIndex={2}
      left={!isSticky ? '50%' : 'auto'}
      transform={!isSticky ? 'translateX(-50%)' : 'none'}
      backgroundColor={isSticky || showLogo ? 'white' : 'none'}
      transition="background-color 0.5s"
      px={{ md: '40px' }}
    >
      <Flex
        justifyContent="space-between"
        align="center"
        w="100%"
        maxW="1108px"
      >
        <Fade in={isSticky || showLogo}>
          <NavigationBrand />
        </Fade>
        <Breadcrumb fontFamily="montserrat" color={colorsGrey[500]} mt="10px">
          {navigationLinks.map(({ link, display, key, nested }) => (
            <StyledBreadcrumbItem key={key}>
              <Link key={link} href={link} passHref legacyBehavior>
                <StyledBreadcrumbLink
                  _hover={{ color: 'primary' }}
                  $isActive={pathname === key}
                >
                  {display}
                </StyledBreadcrumbLink>
              </Link>
              {nested && <NestedNavigation nested={nested} />}
            </StyledBreadcrumbItem>
          ))}
        </Breadcrumb>
      </Flex>
    </CenteredFlex>
  );
};

export default NavigationContent;
