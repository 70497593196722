import { Flex, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import navigationLinks from '../navigationLinks';
import NavigationBrand from '../NavigationBrand';
import { IMobileNavigationProps } from './interfaces';
import BurgerButton from './BurgerButton';
import AccordionNavigation from './NavigationItems/AccordionNavigation';
import LinkNavigation from './NavigationItems/LinkNavigation';
import CenteredFlex from '@components/Shared/CenteredFlex';

export const NAVIGATION_HEIGHT = '70px';

const MobileNavigation: React.FC<IMobileNavigationProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <CenteredFlex
      direction="column"
      backgroundColor="white"
      position="fixed"
      zIndex={1}
      top={0}
      w="100%"
      minHeight={NAVIGATION_HEIGHT}
    >
      <Flex width="100%" justify="space-between" align="center" px={5}>
        <NavigationBrand />
        <BurgerButton onClick={onToggle} />
      </Flex>
      <Flex
        direction="column"
        height={isOpen ? 320 : 0}
        position="fixed"
        top={NAVIGATION_HEIGHT}
        zIndex={1}
        backgroundColor="white"
        width="100%"
        transition="height 0.3s ease-in-out"
        overflow="hidden"
      >
        <Flex direction="column" pb={5} px={5} h="100%">
          {navigationLinks.map(({ link, display, nested }) =>
            nested ? (
              <AccordionNavigation
                key={link}
                display={display}
                link={link}
                nested={nested}
              />
            ) : (
              <LinkNavigation key={link} display={display} link={link} />
            )
          )}
        </Flex>
      </Flex>
    </CenteredFlex>
  );
};

export default MobileNavigation;
