import Link from 'next/link';
import { Text, TextProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { INavigationLink } from '../../interfaces';
import { colorsGreen, colorsGrey } from '@src/theme/chakraTheme/colors';

const LinkNavigation: React.FC<INavigationLink & { textProps?: TextProps }> = ({
  link,
  display,
  textProps,
}) => {
  const { asPath } = useRouter();
  return (
    <Link href={link} passHref>
      <Text
        mb="15px"
        cursor="pointer"
        color={colorsGrey[500]}
        pos="relative"
        w="fit-content"
        _after={{
          opacity: asPath === link ? 1 : 0,
          content: '""',
          bgColor: colorsGreen[300],
          pos: 'absolute',
          w: '75%',
          h: '3px',
          left: 0,
          bottom: '-2px',
        }}
        {...textProps}
      >
        {display}
      </Text>
    </Link>
  );
};

export default LinkNavigation;
